import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import VCard from "vcard-creator"
import QRCode from "qrcode.react"
import { hyphenated } from "hyphenated"
import de from "hyphenated-de"

import Seo from "../components/seo"
import Localize from "../components/localize"
import BackButton from "../components/backButton"

import { In, Xin, Profile } from "../svgs/svg"

import { LeftBackArrow } from "../shared/icons/icons"

const TeamProfile = ({ data: { profile, site }, pageContext, location }) => {
  let title = profile.name
  let role = ""

  profile.role.forEach(rol => {
    role = `${role} ${rol}`
  })

  const [text, setText] = useState("")
  const [route, setRoute] = useState("")
  const lan = pageContext.locale
  const firstName = profile.firstName ? profile.firstName : ""
  const lastName = profile.lastName ? profile.lastName : ""
  const prefix = profile.prefix ? profile.prefix : ""
  const companyName = profile.companyName ? profile.companyName : ""

  const getVsCard = () => {
    const myVCard = new VCard()
    myVCard.addName(lastName.trim(), firstName.trim(), "", prefix)
    myVCard.addPhoneNumber(profile.phone)
    myVCard.addCompany(companyName)
    myVCard.addEmail(profile.email)
    return myVCard.toString()
  }
  useEffect(() => {
    if (typeof window !== undefined) {
      setRoute(
        window.sessionStorage.getItem("route-en") ||
          window.sessionStorage.getItem("route-de")
          ? lan === "en"
            ? window.sessionStorage.getItem("route-en")
            : window.sessionStorage.getItem("route-de")
          : `/${lan}/team/`
      )
      setText(
        window.sessionStorage.getItem("text-en") ||
          window.sessionStorage.getItem("text-de")
          ? lan === "en"
            ? window.sessionStorage.getItem("text-en")
            : window.sessionStorage.getItem("text-de")
          : "Team"
      )
    }
  }, [lan])
  return (
    <>
      <Seo
        lan={lan}
        title={title + "-" + role}
        description={role}
        image={profile.mainImage.asset && profile.mainImage.asset.url}
        url={`${site.siteMetadata.siteUrl}/${lan}/${pageContext.url}/`}
        typ="article"
        titleTemplate={`${title + "-" + role} - Haackschubert`}
      />
      <div
        id="team"
        className="team-home-wrapper team-button no--margin-menu padding--huge-menu-sw"
      >
        <BackButton
          lan={lan}
          state={location.state}
          style={{ right: "calc(50% - 696px)", top: "133px" }}
          to={route}
          text={text}
        />
        <div className="team-front-detail">
          <div className="teamprofileimage p--25">
            {profile.mainImage ? (
              profile.mainImage.asset ? (
                profile.mainImage.asset.url ? (
                  <picture>
                    <source
                      media="(max-width: 540px)"
                      loading="lazy"
                      srcSet={`${profile.mainImage.asset.url}?h=400`}
                    />
                    <source
                      media="(max-width: 1024px)"
                      srcSet={`${profile.mainImage.asset.url}?h=500`}
                      loading="lazy"
                    />
                    <img
                      src={`${profile.mainImage.asset.url}?h=800`}
                      loading="lazy"
                      style={{ objectFit: "cover" }}
                      width="100%"
                      height="100%"
                      alt="img"
                    />
                  </picture>
                ) : (
                  <Profile />
                )
              ) : (
                <Profile />
              )
            ) : (
              <Profile />
            )}
            <div className="triangle"></div>
          </div>
          <div className="team-name">
            <div
              className={
                !profile.xing && !profile.linkedIn
                  ? "row team-name-detail-2"
                  : "row team-name-detail"
              }
            >
              <div className="col col-2" style={{ width: "100%" }}>
                <h1 style={{ maxHeight: "95px", marginLeft: "-2px" }}>
                  {lan === "en"
                    ? hyphenated(profile.name)
                    : hyphenated(profile.name, { language: de })}
                </h1>
                <div className="fl-res">
                  <div className="div-team-name">
                    {profile.role.length > 0 &&
                      profile.role.map((exp, index) => {
                        return (
                          <p className="role-desc" key={index}>
                            {lan === "en"
                              ? hyphenated(exp)
                              : hyphenated(exp, { language: de })}
                          </p>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "10px" }}>
              <div className="col col-sp">
                <ul>
                  <li>
                    <h6>
                      {lan === "en" ? "Contact Details" : "Kontakt Details"}
                    </h6>
                  </li>

                  <li>
                    <strong>Email:</strong>
                    <br />
                    <a
                      href={`mailto:${profile.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {profile.email ? profile.email : "Not Provided"}
                    </a>
                  </li>
                  <div className="contact-desc p-0 border-none">
                    <li className="contact-li" style={{ borderBottom: "none" }}>
                      <strong>{lan === "en" ? "Phone" : "Telefon"}:</strong>
                      <br />
                      <a href={`tel:${profile.phone}`}>{profile.phone}</a>
                    </li>

                    <li className="li-contact">
                      <strong>{lan === "en" ? "Fax" : "Telefax"}:</strong>
                      <br />
                      <a href={`tel:${profile.fax}`}>{profile.fax}</a>
                    </li>
                  </div>

                  <div className="links-div">
                    {(profile.xing || profile.linkedIn) && (
                      <li className="main-soc-div">
                        <strong>Social Media: </strong>
                        <br />
                        <div className="soc-div">
                          {profile.xing && (
                            <div>
                              <a
                                href={profile.xing}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Xin />
                              </a>
                            </div>
                          )}
                          {profile.linkedIn && (
                            <div style={{ marginLeft: "14px" }}>
                              <a
                                href={profile.linkedIn}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <In />
                              </a>
                            </div>
                          )}
                        </div>
                      </li>
                    )}
                    <li
                      className={
                        !(profile.xing && profile.linkedIn)
                          ? "qr-div-d"
                          : "qr-div"
                      }
                    >
                      <div
                        className={
                          !(profile.xing && profile.linkedIn)
                            ? "adjust-qrcode-width"
                            : "adjust-qrcode"
                        }
                      >
                        <strong>
                          {lan === "en"
                            ? "Scan to save contact"
                            : "Zum Speichern des Kontakts scannen"}
                          :{" "}
                        </strong>
                      </div>
                      <QRCode className="qrcode" value={getVsCard()} />
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {profile.education.length > 0 && (
            <div className="col col-2">
              <ul>
                <li>
                  <h6>{lan === "en" ? "Education" : "Ausbildung"}</h6>
                </li>
                {profile.education.map((res, index) => (
                  <li key={index}>
                    {lan === "en"
                      ? hyphenated(res)
                      : hyphenated(res, { language: de })}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {profile.languages.length > 0 && (
            <div className="col col-2">
              <ul>
                <li>
                  <h6>{lan === "en" ? "Languages" : "Sprachen"}</h6>
                </li>
                {profile.languages.map((lan, index) => (
                  <li key={index}>{lan.title}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="row">
          {profile.focusOfActivity.length > 0 && (
            <div className="col col-2">
              <ul>
                <li>
                  <h6>
                    {lan === "en"
                      ? "Focus Of Activity"
                      : "Tätigkeitsschwerpunkt"}
                  </h6>
                </li>
                {profile.focusOfActivity.map((activity, index) => (
                  <li key={index}>
                    {lan === "en"
                      ? hyphenated(activity)
                      : hyphenated(activity, { language: de })}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {profile.membership.length > 0 && (
            <div className="col col-2">
              <ul>
                <li>
                  <h6>{lan === "en" ? "Memberships" : "Mitgliedschaften"}</h6>
                </li>
                {profile.membership.map((mem, index) => (
                  <li key={index}>
                    {lan === "en"
                      ? hyphenated(mem)
                      : hyphenated(mem, { language: de })}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="buttons">
          <Link
            state={location.state}
            className="button close contact"
            to={`/${lan}/team/`}
          >
            <div style={{ top: "10px" }}></div>
            <span
              style={{
                top: "1px",
              }}
              className="icon-arrow_left"
            >
              <LeftBackArrow fill="white" />
            </span>
            {lan === "en" ? "Back to Team" : "Zurück zu Team"}
          </Link>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    profile: sanityTeamProfile(slug: { current: { eq: $slug } }) {
      name
      phone
      role
      education
      focusOfActivity
      firstName
      lastName
      prefix
      companyName
      fax
      email
      membership
      xing
      linkedIn
      expertise {
        title {
          _type
          en
          de
        }
      }
      mainImage {
        asset {
          url
        }
      }
      languages {
        title
      }
    }
  }
`

export default Localize(TeamProfile)
